import { Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Unstable_Grid2 as Grid } from "@mui/material";

import OffExchangeAggregates from "./clientOffExchangeWallets/OffExchangeAggregates.tsx";
import OffExchangeWalletGrid from "./clientOffExchangeWallets/OffExchangeWalletGrid.tsx";
import { innerGridContainer, subText, wrapperContainer } from "./styles.tsx";

function ClientOffExchangeWallets() {
  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Typography variant="h3" data-testid="offexchange-wallets">
        Off Exchange
      </Typography>
      <Typography variant="body1" sx={subText}></Typography>

      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Container sx={innerGridContainer} data-testid="offexchange-walletsTable">
              <OffExchangeAggregates />
              <OffExchangeWalletGrid />
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default ClientOffExchangeWallets;
