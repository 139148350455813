import { color, formatCurrency, Icons } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React from "react";
import { useLoaderData } from "react-router-dom";

import { AssetSVGIcon } from "../../../components/CustomSVG/AssetSVGIcon.tsx";
import QuantityDisplay from "../../../components/quantityDisplay/QuantityDisplay.tsx";
import { useRootContext } from "../../../RootLayout.tsx";
import { Wallet, WalletType } from "../../../services/openAPI/internal/api.ts";
import {
  convertPricingInfoArrayIntoMap,
  getMarketPriceForWallet,
  getPriceByAssetQty,
  getSVGStringForTicker,
} from "../../../utils/dataUtils.ts";

function OffExchangeWalletGrid() {
  const { wallets } = useLoaderData() as {
    wallets: Wallet[];
  };
  const { priceFeed, assets } = useRootContext();
  const pricingInfoMap = convertPricingInfoArrayIntoMap(priceFeed);

  const clientOffExchangeWallets = wallets.filter((wallet) => wallet.type === WalletType.OffExchange);
  const tableHeader = {
    color: color.techBlack["700"],
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontWeight: 500,
    noWrap: "true",
  };

  const getTickerForWallet = (wallet: Wallet) => {
    return assets.find((asset) => asset.symbol === wallet.assetSymbol)?.ticker || "";
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={[tableHeader, { pl: 1 }]} width="250">
              ASSET
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader} width="250">
              TOTAL BALANCE
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader} width="300">
              AVAILABLE BALANCE
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader} width="200">
              CREDIT
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader} width="200">
              COLLATERAL
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader} width="200">
              MARKET PRICE
            </TableCell>
            <TableCell component="th" align="right" sx={tableHeader}>
              <Stack direction={"row"} sx={{ mt: 1, mr: -1 }}>
                EXCHANGE
                <Tooltip title={<Typography variant="body2">Exchange name</Typography>} placement="top">
                  <Box>
                    <SvgIcon component={Icons.InformationIcon} sx={{ width: 25, height: 25 }} />
                  </Box>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientOffExchangeWallets.map((wallet: Wallet, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row" sx={{ pl: 0.2 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon
                      component={() =>
                        AssetSVGIcon({
                          svgString: getSVGStringForTicker(assets, wallet.assetTicker),
                          title: wallet.assetTicker,
                          sx: { width: 25, height: 25, mx: 1 },
                        })
                      }
                      inheritViewBox
                    />
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: 500 }}>
                        <span>{assets.find((asset) => asset.symbol === wallet.assetSymbol)?.name || ""}</span>
                      </Typography>
                      <Typography variant="body2">{wallet.assetTicker as string}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <QuantityDisplay quantity={wallet.quantity} ticker={getTickerForWallet(wallet)} />{" "}
                    {getTickerForWallet(wallet)}
                  </Typography>
                  <Typography variant={"body2"}>
                    {formatCurrency(getPriceByAssetQty(getTickerForWallet(wallet), wallet.quantity, priceFeed))}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <QuantityDisplay quantity={wallet.availableBalance || 0.0} ticker={getTickerForWallet(wallet)} />{" "}
                    {getTickerForWallet(wallet)}
                  </Typography>
                  <Typography variant={"body2"}>
                    {formatCurrency(
                      getPriceByAssetQty(getTickerForWallet(wallet), wallet.availableBalance || 0.0, priceFeed)
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <QuantityDisplay quantity={wallet.creditBalance || 0.0} ticker={getTickerForWallet(wallet)} />{" "}
                    {getTickerForWallet(wallet)}
                  </Typography>
                  <Typography variant={"body2"}>
                    {formatCurrency(
                      getPriceByAssetQty(getTickerForWallet(wallet), wallet.creditBalance || 0.0, priceFeed)
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <QuantityDisplay quantity={wallet.lockedBalance || 0.0} ticker={getTickerForWallet(wallet)} />{" "}
                    {getTickerForWallet(wallet)}
                  </Typography>
                  <Typography variant={"body2"}>
                    {formatCurrency(
                      getPriceByAssetQty(getTickerForWallet(wallet), wallet.lockedBalance || 0.0, priceFeed)
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">{getMarketPriceForWallet(wallet, pricingInfoMap)}</TableCell>
                <TableCell align="right">{wallet.offExchangeExternalAccountName}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OffExchangeWalletGrid;
