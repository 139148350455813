import { Alert, Typography } from "@bakkt/bakkt-ui-components";
import React from "react";

import { CollateralMinimal } from "../../Hooks/useGetCollateralRequest";
import { ReviewTransactionMinimal } from "../../Hooks/useReviewTransaction";
import { Wallet, WalletType } from "../../services/openAPI/internal";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap";

interface ERC20BannerProps {
  wallet: Wallet | null;
  transaction: ReviewTransactionMinimal | Partial<CollateralMinimal>;
}

function ERC20Banner({ wallet, transaction }: ERC20BannerProps) {
  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      <Typography variant={"body2"}>
        {wallet?.type === WalletType.Custody ? transaction?.organizationName : "Cold Trust Fee"}, GAS Balance:{" "}
        {transaction.feeWallet?.availableBalance?.toFixed(8)} {CryptoTickerEnum.ETH}
      </Typography>
      <Typography variant={"body2"}>
        *Please ensure that the client has a balance of at least 0.05 ETH for GAS before processing this withdrawal.
      </Typography>
    </Alert>
  );
}

export default ERC20Banner;
