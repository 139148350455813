import { color } from "@bakkt/bakkt-ui-components";

export const subText = {
  mt: 0.625,
  mb: 2.5,
};

export const innerGridContainer = {
  p: "20px",
};

export const wrapperContainer = {
  mb: "68px",
  mt: "50px",
};

export const gridDivider = {
  mr: "-1px",
};

export const warmColdGridDivider = {
  mr: "-1px",
  mt: "-1px",
  mb: "-10px",
};

export const balancesGridContainer = {
  paddingRight: "-1px",
};

export const tableHeader = {
  color: color.techBlack["700"],
  fontSize: "16px",
  lineHeight: "20px",
};

export const warmColdValues = {
  lineHeight: "51px",
  marginBottom: "8px",
  fontWeight: 400,
};

export const totalValues = {
  lineHeight: "51px",
  marginBottom: "8px",
  fontWeight: 700,
};

export const tableData = {
  color: color.techBlack.main,
  fontSize: "18px",
  lineHeight: "23px",
  letterSpacing: "-0.5px",
};

export const phoneData = {
  color: color.techBlack.main,
  fontWeight: "500px",
  fontSize: "32px",
  lineHeight: "40px",
  letterSpacing: "-0.5px",
};

export const phoneHeader = {
  color: color.techBlack["700"],
  fontSize: "16px",
  lineHeight: "20px",
  pt: "30px",
};

export const internalGrid = {
  padding: "20px 40px 20px 40px",
  ".MuiDataGrid-main": {
    "&>.MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflow: "hidden",
  },
};

export const orgTableHeader = {
  pt: "30px",
  pb: "10px",
};

export const errorIcon = {
  color: color.engagingOrange.dark,
  width: 15,
  height: 15,
};

export const offExgTypography = {
  variant: "caption",
  alignContent: "center",
  textAlign: "center",
  padding: 1,
  noWrap: "true",
};

export const offExgAggregateHeader = {
  color: color.techBlack["700"],
  backgroundColor: color.oysterGray.main,
};
