import { Alert, Button, formatCurrency, Icons, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Divider, Paper, SvgIcon, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, Link as RouterLink, useLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { RootContextType, useRootContext } from "../../RootLayout";
import { Account, PriceInfo, Wallet, WalletType } from "../../services/openAPI/internal";
import { calculateBalanceTotalsFromWallets, calculateOffExchangeTotalsFromWallets } from "../../utils/dataUtils.ts";
import { isDa } from "../../utils/permissionsUtil.ts";
import ClientWalletBalanceContainer from "./ClientWalletBalanceContainer.tsx";
import {
  gridDivider,
  phoneData,
  phoneHeader,
  tableData,
  tableHeader,
  totalValues,
  warmColdGridDivider,
  wrapperContainer,
} from "./styles";

export const ClientInfo = () => {
  const { priceFeed, userInfo } = useRootContext() as RootContextType;

  const { account, wallets } = useLoaderData() as {
    account: Account;
    wallets: Wallet[];
  };

  const segregatedWalletstTypes: string[] = [WalletType.Custody, WalletType.Trust, WalletType.Deposit];
  const segregatedWallets = wallets.filter((wallet) => segregatedWalletstTypes.includes(wallet.type));
  const offExchangeWallets = wallets.filter((wallet) => wallet.type === WalletType.OffExchange);

  const isOffExchangeOrg = offExchangeWallets.length > 0;
  return (
    <>
      <Container maxWidth="xl" disableGutters sx={wrapperContainer} data-testid="ParentBalanceContainer">
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3" data-testid="clientName" sx={{ mb: 2.5 }}>
              {account.name}
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <Grid container spacing={2}>
            <React.Suspense fallback={<LoadingIndicator />}>
              <Await resolve={priceFeed} errorElement={<Alert severity={"error"}>Error loading wallet data!</Alert>}>
                {(priceFeed: PriceInfo[]) => {
                  const balanceData = calculateBalanceTotalsFromWallets(segregatedWallets, priceFeed);
                  const offExchangeBalance = calculateOffExchangeTotalsFromWallets(offExchangeWallets, priceFeed);
                  return (
                    <>
                      <Grid data-testid="balancesGrid" xs={8} sx={{ pb: 0, pr: 0 }}>
                        <Container data-testid="totalBalanceContainer" sx={{ p: 3.5 }}>
                          <Typography variant="overline" sx={tableHeader} data-testid="label">
                            Total
                          </Typography>
                          <Typography variant="h2" sx={totalValues} data-testid="amount">
                            {formatCurrency(balanceData.total + offExchangeBalance.totalBalance)}
                          </Typography>
                          {/*TODO: Coming back in Phase 2*/}
                          {/*{balanceLabel === "Total Balance" && (*/}
                          {/*  <div data-testid="percentChange">*/}
                          {/*    <UpDownPill direction={"up"} text={"6.8%"} />*/}
                          {/*    <span>Last 24 hours</span>*/}
                          {/*  </div>*/}
                          {/*)}*/}
                        </Container>

                        <Divider orientation="horizontal" flexItem />

                        <Grid container spacing={2} data-testid="warmColdBalanceContainer">
                          <ClientWalletBalanceContainer
                            balanceData={balanceData.warm}
                            label="WARM"
                            isOffexchangeEnabled={isOffExchangeOrg}
                          />
                          <Divider orientation="vertical" flexItem sx={warmColdGridDivider} />
                          <ClientWalletBalanceContainer
                            balanceData={balanceData.cold}
                            label="COLD"
                            isOffexchangeEnabled={isOffExchangeOrg}
                          />
                          {isOffExchangeOrg && (
                            <>
                              <Divider orientation="vertical" flexItem sx={warmColdGridDivider} />
                              <ClientWalletBalanceContainer
                                balanceData={offExchangeBalance.totalBalance}
                                label="OFF EXCHANGE"
                                isOffexchangeEnabled={isOffExchangeOrg}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>

                      <Divider orientation="vertical" flexItem sx={gridDivider} />

                      <Grid data-testid="clientAddressGrid" xs={4}>
                        <Grid container data-testid="clientAddressContainer">
                          <Grid xs={12} sx={{ p: 5 }}>
                            <Grid container justifyContent={"space-between"} data-testid="clientAddressHeaderContainer">
                              <Grid xs={9}>
                                <Typography variant="overline" sx={tableHeader} data-testid="address">
                                  Address
                                </Typography>
                              </Grid>
                              <Grid xs={3}>
                                {isDa(userInfo) && (
                                  <RouterLink to={`/clients/${account.id}/edit`}>
                                    <Button variant={"contained"}>
                                      <SvgIcon
                                        component={Icons.EditSmallIcon}
                                        inheritViewBox
                                        sx={{ width: 20, height: 20, pr: 1 }}
                                      />
                                      Edit
                                    </Button>
                                  </RouterLink>
                                )}
                              </Grid>
                            </Grid>

                            <Grid container data-testid="clientAddressDetailContainer">
                              <Grid>
                                <Typography variant="body1" sx={tableData}>
                                  {account.address.street1}
                                </Typography>
                                <Typography variant="body1" sx={tableData}>
                                  {account.address.street2}
                                </Typography>
                                <Typography variant="body1" sx={tableData}>
                                  {account.address.city + "," + account.address.state + "," + account.address.zipCode}
                                </Typography>
                                <Typography variant="body1" sx={tableData}>
                                  {account.address.country}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container data-testid="clientPhoneContainer">
                              <Grid xs={12}>
                                <Typography variant={"subtitle1"} sx={phoneHeader}>
                                  Phone
                                </Typography>
                                <Typography variant={"h3"} sx={phoneData}>
                                  {account.phone.countryCode} {account.phone.number}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Await>
            </React.Suspense>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};
