import { formatCurrency, Typography } from "@bakkt/bakkt-ui-components";
import { Label } from "@mui/icons-material";
import { Container, Unstable_Grid2 as Grid } from "@mui/material";

import { tableHeader, warmColdValues } from "./styles";

interface ClientWalletBalanceContainerProps {
  balanceData: number;
  label: string;
  isOffexchangeEnabled?: boolean;
}

function ClientWalletBalanceContainer({ balanceData, label, isOffexchangeEnabled }: ClientWalletBalanceContainerProps) {
  return (
    <Grid xs={isOffexchangeEnabled ? 4 : 6}>
      <Container data-testid={`${Label}Container`} sx={{ pt: 2.5, pr: 5, pb: 2.5, pl: 3.75 }}>
        <Typography variant="overline" sx={tableHeader} data-testid="label">
          {label}
        </Typography>
        <Typography variant="h5" sx={warmColdValues} data-testid="amount">
          {formatCurrency(balanceData || 0)}
        </Typography>

        {/*TODO: Coming back in Phase 2*/}
        {/*{balanceLabel === "Total Balance" && (*/}
        {/*  <div data-testid={"percentChange"}>*/}
        {/*    <UpDownPill direction={"down"} text={"3.2%"} />*/}
        {/*    <span>Last 24 hours</span>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Container>
    </Grid>
  );
}

export default ClientWalletBalanceContainer;
