import { Alert } from "@bakkt/bakkt-ui-components";
import React, { useEffect } from "react";
import { Await, defer, Outlet, useLoaderData } from "react-router-dom";

import Alerts from "../../components/alerts/Alerts";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { accounts, fetchMockDataPromiseWithDelay, organizations, users, wallets } from "../../services/mockData";
import { Account, Organization, User, Wallet, WalletType } from "../../services/openAPI/internal";
import { AccountService, OrganizationService, UserService, WalletService } from "../../services/serviceLoader";
import { getAccount, getOrgs, getUsersByAccountId, shouldUseMockData } from "../../utils/dataUtils.ts";
import ClientBreadCrumbs from "./ClientBreadCrumb";
import { ClientInfo } from "./ClientInfo";
import ClientOffExchangeWallets from "./ClientOffExchangeWallets.tsx";
import { ClientOrgs } from "./ClientOrgs";
import { ClientUsers } from "./ClientUsers";
import ClientWallets from "./ClientWallets";

export const ClientDetail = () => {
  const { wallets } = useLoaderData() as {
    account: Account;
    organizations: Organization[];
    users: User[];
    wallets: Wallet[];
  };
  const { setAccountChanges } = useRootContext();
  useEffect(() => {
    //clear RootContext
    setAccountChanges((prevState) => ({
      ...prevState,
      account: null,
      organizations: [],
      users: [],
      isOrgEdited: false,
    }));
  }, []);

  const offExchangeWallets = wallets.filter((wallet) => wallet.type === WalletType.OffExchange);
  return (
    <>
      <Outlet context={useRootContext()} />
      <Alerts />
      <ClientBreadCrumbs />
      <React.Suspense fallback={<LoadingIndicator />}>
        <Await
          resolve={wallets}
          errorElement={<Alert severity={"error"}>Error loading pricing info data for client.</Alert>}
        >
          <ClientInfo />
        </Await>
      </React.Suspense>
      <ClientOrgs />
      <ClientWallets />
      {offExchangeWallets.length > 0 && <ClientOffExchangeWallets />}
      <ClientUsers />
    </>
  );
};

export async function loader({ params }: any) {
  try {
    const accountId = Number(params.accountId);

    const accountPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(getAccount(accountId, accounts) as Account, 200)
      : AccountService.getAccount(accountId);

    const walletsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(wallets, 200)
      : WalletService.getWallets(accountId);

    const orgsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(getOrgs(accountId, organizations) as Organization[], 200)
      : OrganizationService.getOrganizations(accountId);

    const usersPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(getUsersByAccountId(accountId, users) as User[], 200)
      : UserService.getUsers(accountId);

    return defer({
      account: await accountPromise,
      wallets: await walletsPromise,
      organizations: await orgsPromise,
      users: await usersPromise,
    });
  } catch (e) {
    console.log("Error fetching Clients data: ", e);
    return {};
  }
}
