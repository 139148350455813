import { DataGridPremium, formatUnixTime, Tab, Typography, UserInfo } from "@bakkt/bakkt-ui-components";
import { Box, Paper, Tabs, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import React, { memo, useState } from "react";
import { Link as RouterLink, useRouteLoaderData } from "react-router-dom";

import {
  PolicyActionDetails,
  PolicyInstanceRequestCategoryEnum,
  PolicyStatusEnum,
} from "../../services/openAPI/internal";
import { innerGridContainer } from "./styles";

enum RequestTabEnum {
  Pending = "Pending",
  Completed = "Completed",
}

const tabs = [RequestTabEnum.Pending, RequestTabEnum.Completed];

interface RequestsGridProps {
  policyAllRequests: PolicyActionDetails[];
  policyInitiatedRequests: PolicyActionDetails[];
}

export const RequestsGrid = memo(function ({ policyAllRequests, policyInitiatedRequests }: RequestsGridProps) {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
  };

  const [selectedTab, setSelectedTab] = useState(RequestTabEnum.Pending);

  const filteredRequests = policyAllRequests.filter((policyRequest) => {
    if (selectedTab === RequestTabEnum.Completed) {
      return (
        policyRequest.policySequenceStatus === PolicyStatusEnum.Completed ||
        policyRequest.policySequenceStatus === PolicyStatusEnum.Cancelled
      );
    } else {
      return (
        policyRequest.policySequenceStatus !== PolicyStatusEnum.Completed &&
        policyRequest.policySequenceStatus !== PolicyStatusEnum.Cancelled
      );
    }
  });

  const theme = useTheme();
  const linkStyle = {
    color: theme.palette.primary.main,
  };

  const handleTabChange = (event: React.SyntheticEvent, value: string) => {
    const ev = event;
    setSelectedTab(value as RequestTabEnum);
  };

  const getRouteString = (row: PolicyActionDetails): string => {
    const isTransactionPolicy = row.policyInstanceRequestCategory === PolicyInstanceRequestCategoryEnum.Transaction;

    if (isTransactionPolicy && row.policyInstanceRequestType?.includes("COLLATERAL")) {
      return row.policyInstanceRequestType?.split("_")[1]?.toLowerCase() as string;
    } else if (isTransactionPolicy && row.policyInstanceRequestType?.includes("SETTLEMENT")) {
      return "SETTLEMENT".toLowerCase();
    }
    return isTransactionPolicy
      ? (row.policyInstanceRequestType?.toLowerCase() as string)
      : (row.policyInstanceRequestCategory?.toLowerCase() as string);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between">
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
            {tabs.map((tab) => (
              <Tab key={tab} value={tab} label={tab}></Tab>
            ))}
          </Tabs>
          <GridToolbarQuickFilter />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "policyInstanceRequestCategory",
      headerName: "REQUEST",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return <Typography>{params.value}</Typography>;
        } else {
          const initiatedRequest = policyInitiatedRequests.find(
            (item) => item.policySequenceId === params.row.policySequenceId
          );

          return params.row.policySequenceStatus !== PolicyStatusEnum.Completed ? (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {params.row.policyInstanceRequestType?.replaceAll("_", " ")} {params.value}
              </Box>
            </RouterLink>
          ) : (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {params.row.policyInstanceRequestType?.replaceAll("_", " ")} {params.value}
              </Box>
            </RouterLink>
          );
        }
      },
      width: 250,
    },
    {
      field: "timestamp",
      headerName: "TIMESTAMP",
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return <span>{formatUnixTime(params.value, "long")}</span>;
        }
      },
    },
    {
      field: "requester",
      headerName: "REQUESTER",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
    },
    {
      field: "client",
      headerName: "CLIENT",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
    },
    {
      field: "organization",
      headerName: "ORGANIZATION",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
    },
    {
      field: "approvalCount",
      headerName: "COMPLETE APPROVALS",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.value} of {params.row.consensusNumber}
          </span>
        );
      },
    },
    {
      field: "policySequenceStatus",
      headerName: "STATUS",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return <Typography>{params.value}</Typography>;
        } else {
          const initiatedRequest = policyInitiatedRequests.find(
            (item) => item.policySequenceId === params.row.policySequenceId
          );

          return params.row.approvalCount === 0 &&
            (params.value !== PolicyStatusEnum.Completed || params.value !== PolicyStatusEnum.Cancelled) ? (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {params.value}
              </Box>
            </RouterLink>
          ) : params.row.approvalCount != params.row.consensusNumber &&
            params.row.approvalCount > 0 &&
            params.value !== PolicyStatusEnum.Completed &&
            params.value !== PolicyStatusEnum.Cancelled ? (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {"PENDING OPS APPROVAL"}
              </Box>
            </RouterLink>
          ) : params.value === PolicyStatusEnum.Cancelled ? (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {params.value}
              </Box>
            </RouterLink>
          ) : (
            <RouterLink
              to={`/review-${getRouteString(params.row)}/${
                JSON.parse(String(params.row.policyInstancePayload)).entityId
              }`}
              state={{
                data: initiatedRequest ? initiatedRequest : params.row,
                approved: initiatedRequest ? false : true,
              }}
              style={linkStyle}
            >
              <Box component="span" sx={{ textDecoration: "underline" }}>
                {"COMPLETED"}
              </Box>
            </RouterLink>
          );
        }
      },
      flex: 1,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredRequests };

  return (
    <Paper>
      <Grid container>
        <DataGridPremium
          sx={[innerGridContainer, { minHeight: 300 }]}
          {...data}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
            footer: () => null,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "timestamp", sort: "desc" }],
            },
          }}
          getRowId={(row) => row.policySequenceId}
        />
      </Grid>
    </Paper>
  );
});
