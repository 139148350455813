import { formatDollarAmountUsd, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useLoaderData } from "react-router-dom";

import { useRootContext } from "../../../RootLayout";
import { Wallet, WalletType } from "../../../services/openAPI/internal";
import { calculateOffExchangeTotalsFromWallets } from "../../../utils/dataUtils";
import { offExgAggregateHeader, offExgTypography } from "../styles";

function OffExchangeAggregates() {
  const { wallets } = useLoaderData() as {
    wallets: Wallet[];
  };
  const { priceFeed } = useRootContext();

  const balanceSummary = calculateOffExchangeTotalsFromWallets(
    wallets.filter((wallet) => wallet.type === WalletType.OffExchange),
    priceFeed
  );

  return (
    <Paper>
      <Grid container spacing={2} xs={12}>
        <Container data-testid="offExchange-totals" sx={offExgAggregateHeader}>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={6}>
            <Typography sx={offExgTypography}></Typography>
            <Typography sx={offExgTypography}>
              TOTAL VALUE: {formatDollarAmountUsd(balanceSummary.totalBalance)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL AVAILABLE: {formatDollarAmountUsd(balanceSummary.totalAvailable)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL CREDIT: {formatDollarAmountUsd(balanceSummary.totalCredit)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL COLLATERAL: {formatDollarAmountUsd(balanceSummary.totalCollateral)}
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </Paper>
  );
}

export default OffExchangeAggregates;
