import { API_URL } from "../utils/dataUtils";
import {
  AccountApiFactory,
  AllowlistApiFactory,
  AssetsApiFactory,
  Configuration,
  FusionAuthApiFactory,
  HistorySummaryApiFactory,
  OffExchangeApiFactory,
  OrganizationApiFactory,
  PolicyApiFactory,
  PriceApiFactory,
  ReportApiFactory,
  UserApiFactory,
  WalletApiFactory,
  WalletTransactionApiFactory,
} from "./openAPI/internal";

const configuration: Configuration = new Configuration();
const baseUrl = API_URL + "/custody";

export const AccountService = AccountApiFactory(configuration, baseUrl);
export const OrganizationService = OrganizationApiFactory(configuration, baseUrl);
export const WalletService = WalletApiFactory(configuration, baseUrl);
export const WalletTransactionService = WalletTransactionApiFactory(configuration, baseUrl);
export const UserService = UserApiFactory(configuration, baseUrl);
export const PolicyService = PolicyApiFactory(configuration, baseUrl);
export const AllowlistService = AllowlistApiFactory(configuration, baseUrl);
export const PriceService = PriceApiFactory(configuration, baseUrl);
export const FusionAuthService = FusionAuthApiFactory(configuration, baseUrl);
export const HistoryService = HistorySummaryApiFactory(configuration, baseUrl);
export const ReportService = ReportApiFactory(configuration, baseUrl);
export const AssetService = AssetsApiFactory(configuration, baseUrl);
export const OffExchangeService = OffExchangeApiFactory(configuration, baseUrl);
