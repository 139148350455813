import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { registerAxiosConfig } from "./config/axiosConfig";
import Allowlist, { loader as allowlistLoader } from "./pages/allowlist/Allowlist";
import ApproveDeclineAllowlist, {
  action as approveDeclineAllowlistAction,
} from "./pages/allowlist/ApproveDeclineAllowlist";
import ReviewAllowlist, { loader as reviewAllowlistLoader } from "./pages/allowlist/ReviewAllowlist";
import AllTransactions, { loader as allTransactionsLoader } from "./pages/allTransactions/AllTransactions";
import AuditLogs, { loader as auditLoader } from "./pages/audit/AuditLogs";
import { ClientDetail, loader as clientDetailLoader } from "./pages/clientDetail/ClientDetail";
import AddEditClient, { action as addClientAction } from "./pages/clients/AddEditClient";
import { Clients, loader as clientsLoader } from "./pages/clients/Clients";
import ConfirmAccountDialog, {
  action as confirmAccountAction,
  loader as confirmAccountLoader,
} from "./pages/clients/ConfirmAccountDialog";
import Dashboard, { loader as dashboardLoader } from "./pages/dashboard/Dashboard";
import ErrorPage from "./pages/error/ErrorPage";
import AddEditOrganization, { action as addOrganizationAction } from "./pages/organizations/AddEditOrganization";
import AddEditUsers, {
  action as addUsersDialogAction,
  loader as addUsersDialogLoader,
} from "./pages/users/AddEditUsers";
import ApproveDeclineWithdraw, {
  action as approveDeclineWithdrawlistAction,
} from "./pages/wallet/ApproveDeclineWithdraw.tsx";
import QuarantineDeposit, { action as quarantineDepositAction } from "./pages/wallet/QuarantineDeposit.tsx";
import ReviewCollateralRequest from "./pages/wallet/ReviewCollateralRequest.tsx";
import ReviewSettlement, { action as reviewSettlementAction } from "./pages/wallet/ReviewSettlement.tsx";
import ReviewTransaction, { action as reviewTransactionAction } from "./pages/wallet/ReviewTransaction.tsx";
import RootLayout, { loader as rootLoader } from "./RootLayout";

registerAxiosConfig();
const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <RootLayout />,
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        id: "dashboard",
        element: <Dashboard />,
        loader: dashboardLoader,
        children: [
          {
            path: "clients/new",
            id: "addClient",
            element: <AddEditClient />,
            action: addClientAction,
          },
          {
            path: "organizations/:accountId",
            id: "addOrganization",
            element: <AddEditOrganization />,
            action: addOrganizationAction,
          },
          {
            path: "users/:accountId",
            id: "addUsersDialog",
            element: <AddEditUsers />,
            loader: addUsersDialogLoader,
            action: addUsersDialogAction,
          },
          {
            path: "clients/:accountId/confirm",
            id: "confirmAccountDialog",
            element: <ConfirmAccountDialog />,
            loader: confirmAccountLoader,
            action: confirmAccountAction,
          },
          {
            path: "review-allowlist/:allowlistId",
            id: "reviewAllowlist",
            element: <ReviewAllowlist />,
            loader: reviewAllowlistLoader,
          },
          {
            path: ":allowlistAction-allowlist/:policyActionId",
            id: "approveDeclineAllowlist",
            element: <ApproveDeclineAllowlist />,
            action: approveDeclineAllowlistAction,
          },
          {
            path: "review-withdraw/:walletTransactionId",
            id: "reviewWithdraw",
            element: <ReviewTransaction />,
          },
          {
            path: "review-transfer/:walletTransactionId",
            id: "reviewTransfer",
            element: <ReviewTransaction />,
          },
          {
            path: ":transactionAction-transaction/:policyActionId",
            id: "approveDeclineTransaction",
            element: <ApproveDeclineWithdraw />,
            action: approveDeclineWithdrawlistAction,
          },
          {
            path: "review-deposit/:walletTransactionId",
            id: "reviewDeposit",
            element: <ReviewTransaction />,
            action: reviewTransactionAction,
          },
          {
            path: "review-collateral/:walletTransactionId",
            id: "reviewCollateral",
            element: <ReviewCollateralRequest />,
          },
          {
            path: "review-settlement/:entityId",
            id: "reviewSettlement",
            element: <ReviewSettlement />,
            action: reviewSettlementAction,
          },
          {
            path: "confirm-quarantine",
            id: "confirmQuarantine",
            element: <QuarantineDeposit />,
            action: quarantineDepositAction,
          },
        ],
      },
      {
        path: "clients",
        id: "clients",
        element: <Clients />,
        loader: clientsLoader,
      },
      {
        path: "transactions/:accountId?",
        id: "transactions",
        element: <AllTransactions />,
        loader: allTransactionsLoader,
      },
      {
        path: "clients/:accountId",
        id: "clientDetail",
        element: <ClientDetail />,
        loader: clientDetailLoader,
        children: [
          {
            path: "edit",
            id: "clientEdit",
            element: <AddEditClient />,
            action: addClientAction,
          },
          {
            path: "organizations/edit",
            id: "organizationEdit",
            element: <AddEditOrganization />,
            action: addOrganizationAction,
          },
          {
            path: "users/edit",
            id: "usersEdit",
            element: <AddEditUsers />,
            loader: addUsersDialogLoader,
          },
          {
            path: "confirm/edit",
            id: "confirmAccountDialogEdit",
            element: <ConfirmAccountDialog />,
            loader: confirmAccountLoader,
            action: confirmAccountAction,
          },
        ],
      },
      {
        path: "allowlisted",
        id: "alllowlisted",
        element: <Allowlist />,
        loader: allowlistLoader,
      },
      {
        path: "audit/:accountId?/:organizationId?",
        id: "audit",
        element: <AuditLogs />,
        loader: auditLoader,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<RouterProvider router={router} />);
