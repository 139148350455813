import { Alert, Paper, Typography, useInterval, UserInfo } from "@bakkt/bakkt-ui-components";
import { Container, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Await, Outlet, useLoaderData, useRouteLoaderData } from "react-router-dom";

import Alerts from "../../components/alerts/Alerts.tsx";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import {
  fetchMockDataPromiseWithDelay,
  policyRequests,
  policyRequestsInitiated,
  wallets,
} from "../../services/mockData.ts";
import { PolicyActionDetails, Wallet } from "../../services/openAPI/internal";
import { PolicyService, WalletService } from "../../services/serviceLoader.ts";
import { shouldUseMockData } from "../../utils/dataUtils.ts";
import Balances from "./Balances.tsx";
import { RequestsGrid } from "./RequestsGrid.tsx";
import { subText, wrapperContainer } from "./styles";

const Dashboard = () => {
  const { shouldRefreshPolicyItems, setShouldRefreshPolicyItems } = useRootContext();
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
  };

  const [allPolicyRequests, setAllPolicyRequests] = useState<PolicyActionDetails[]>([]);
  const [userPolicyRequests, setUserPolicyRequests] = useState<PolicyActionDetails[]>([]);

  const { walletsPromise, quarantinePromise } = useLoaderData() as {
    walletsPromise: Promise<Wallet[]>;
    quarantinePromise: Promise<Wallet[]>;
  };

  const fetchWalletsPromise = useMemo(
    () => Promise.all([walletsPromise, quarantinePromise]),
    [walletsPromise, quarantinePromise]
  );

  const getPolicyRequestItems = async () => {
    const policyAllRequests = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(policyRequests, 2000)
      : await PolicyService.getAllOpsPolicySequences();

    setAllPolicyRequests(policyAllRequests as PolicyActionDetails[]);

    const policyInitiatedRequests = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(policyRequestsInitiated, 200)
      : await PolicyService.getInitiatedOpsPolicyActions(userInfo.userId);

    setUserPolicyRequests(policyInitiatedRequests as PolicyActionDetails[]);

    if (shouldRefreshPolicyItems) {
      setShouldRefreshPolicyItems(false);
    }
  };

  useEffect(() => {
    getPolicyRequestItems();
  }, []);

  // refresh every 1 sec when polling for new item - otherwise refresh every 15 sec
  const refreshIntervalMilliseconds = shouldRefreshPolicyItems ? 1000 : 15000;
  useInterval(async () => {
    getPolicyRequestItems();
  }, refreshIntervalMilliseconds);

  return (
    <>
      <Outlet context={useRootContext()} />
      <Alerts />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h3" data-testid="balances">
              Balances
            </Typography>
            <Typography variant="body1" sx={subText}>
              {/* leave in place for proper layout */}
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <React.Suspense
            fallback={
              <Stack sx={{ mt: 12, mb: 7 }}>
                <LoadingIndicator />
              </Stack>
            }
          >
            <Await
              resolve={fetchWalletsPromise}
              errorElement={<Alert severity="error">Error loading balance data!</Alert>}
            >
              {([wallets, quarantined]) => <Balances wallets={wallets} quarantined={quarantined} />}
            </Await>
          </React.Suspense>
        </Paper>
      </Container>

      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3" data-testid="balances">
              Requests
            </Typography>
            <Typography variant="body1" sx={subText}>
              {/* leave in place for proper layout */}
            </Typography>
          </Grid>
          <Grid xs={3} container direction="column" alignItems="flex-end">
            <div
              style={{
                maxWidth: "300px",
                marginTop: "47px",
                marginRight: "-8px",
              }}
            >
              {/* Switch here if needed */}
            </div>
          </Grid>
        </Grid>

        {shouldRefreshPolicyItems ? (
          <LoadingIndicator />
        ) : (
          <Paper>
            <Grid container spacing={2} data-testid="requestsContainer">
              <Grid xs={12} sx={{ paddingBottom: 0 }}>
                <RequestsGrid policyAllRequests={allPolicyRequests} policyInitiatedRequests={userPolicyRequests} />
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>
    </>
  );
};

export default Dashboard;

export async function loader() {
  try {
    const walletsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(wallets, 3000)
      : WalletService.getWallets(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "false",
          undefined
        );

    const quarantinePromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(wallets, 3000)
      : WalletService.getWallets(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "true",
          undefined
        );

    return {
      walletsPromise: walletsPromise,
      quarantinePromise: quarantinePromise,
    };
  } catch (e) {
    console.log("Error fetching balance data: ", e);
    return e;
  }
}
